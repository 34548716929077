import React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import LocalTaxiIcon from '@mui/icons-material/LocalTaxi';
import PeopleIcon from '@mui/icons-material/People';
import BuildIcon from '@mui/icons-material/Build';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';

const Services = () => {
  const services = [
    { title: 'Cab Drivers Supply', icon: <LocalTaxiIcon fontSize="large" />, description: 'Experienced and professional cab drivers available for hire.' },
    { title: 'Mechanic Help', icon: <BuildIcon fontSize="large" />, description: '24/7 mechanic support to ensure your vehicles are in top condition. we assign a dedicated mechanic to help you,  anywhere in AP' },
    { title: 'Bus Rental', icon: <DirectionsBusIcon fontSize="large" />, description: 'Wide range of buses available for rental, including mini buses, travelers, 13-seater, 20-seater, and 40-seater buses.' },
  ];

  return (
    <div style={{ padding: 20, textAlign: 'center' }}>
      <Typography variant="h4" style={{ color: 'black', padding: 10, borderRadius: 5, marginBottom: 20, textShadow: '2px 2px grey', fontWeight: '900', fontFamily: 'Merriweather, sans-serif' }}>
        Special Services
      </Typography>

      <Grid container spacing={3} justifyContent="center">
        {services.map((service, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Paper elevation={3} style={{ padding: 20, height: '100%' }}>
              <IconButton style={{ margin: 'auto', display: 'block' }}>{service.icon}</IconButton>
              <Typography variant="h6" style={{ marginTop: 10 }}>{service.title}</Typography>
              <Typography variant="body2">{service.description}</Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Services;
