import React from 'react';
import { Container, Row, Col, Card, Carousel } from 'react-bootstrap';
// import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';

const testimonials = [
  {
    id: 1,
    name: 'Girish',
    role: 'Vizag',
    imageSrc: 'https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(1).webp',
    text: 'Siddarth Car Travels is great! The cars are nice, and the drivers are friendly. I had a really good time.',
  },
  {
    id: 2,
    name: 'Priya',
    role: 'Rajahmundry',
    imageSrc: 'https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(2).webp',
    text: 'I love Siddarth Car Travels! The service is good, and the cars are clean. I always feel comfortable when I travel with them',
  },
  {
    id: 3,
    name: 'Sameer',
    role: 'Vijayawada',
    imageSrc: 'https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(3).webp',
    text: 'Siddarth Car Travels is awesome! The drivers are nice, and the cars are cool. I had a great experience.',
  },
  {
    id: 4,
    name: 'Sai Kiran',
    role: 'Tenali',
    imageSrc: 'https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(3).webp',
    text: 'I recommend Siddarth Car Travels. The service is good, and the staff is friendly. I felt safe and happy during my journey.',
  },
  {
    id: 5,
    name: 'Druv',
    role: 'Vijayawada',
    imageSrc: 'https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(3).webp',
    text: 'Good. Drivers are Polite. Thanks Siddarth Car Travels',
  }
];

const starStyle = {
    color: '#F4CE14',  // Set the star color to yellow
    padding: '2px'  // Add some padding for better appearance
  };

const Testimonials = () => {
  return (
    <Container fluid className="py-5 gradient-custom">
      <Row className="d-flex justify-content-center">
        <Col md="12">
          {/* <div className="text-center mb-4 pb-2">
            <ChevronLeftIcon size="3x" className="text-white" />
          </div> */}
          <Card>
            <Card.Body className="px-4 py-5">
              <Carousel>
                {testimonials.map((testimonial) => (
                  <Carousel.Item key={testimonial.id}>
                    <Row className="d-flex justify-content-center" style={{textAlign:'center'}}>
                      <Col >
                        <Row>
            
                          <div>
                          <h4 className="mb-4">{`${testimonial.name} - ${testimonial.role}`}</h4>
                            <p className="mb-0 pb-3">{testimonial.text}</p>
                                <StarIcon style={starStyle} fontSize="large" />
                                <StarIcon style={starStyle} fontSize="large" />
                                <StarIcon style={starStyle} fontSize="large" />
                                <StarIcon style={starStyle} fontSize="large" />
                                <StarIcon style={starStyle} fontSize="large" />
                
                        </div>
                        </Row>
                      </Col>
                    </Row>
                  </Carousel.Item>
                ))}
              </Carousel>
            </Card.Body>
          </Card>
          {/* <div className="text-center mt-4 pt-2">
            <ChevronRightIcon size="3x" className="text-white" />
          </div> */}
        </Col>
      </Row>
    </Container>
  );
};

export default Testimonials;
