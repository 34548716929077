import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import car1 from '../Images/1.png';
import car2 from '../Images/2.png';
import car3 from '../Images/3.png';
import car4 from '../Images/4.png';
import car5 from '../Images/5.png';
import car6 from '../Images/6.png';
import car7 from '../Images/7.png';
import car8 from '../Images/8.png';
import car9 from '../Images/9.png';
import car10 from '../Images/10.png';
import car11 from '../Images/11.png';
import car12 from '../Images/12.png';



const Cars = () => {
  const cars = [
    { brand: '4 Seater', model: 'swift dzire', imageUrl: car1 },
    { brand: '4 Seater', model: 'Etios', imageUrl: car2 },
    { brand: '7 Seater', model: 'Ertiga', imageUrl: car3 },
    { brand: '7 Seater', model: 'Innova', imageUrl: car4 },
    { brand: '13 Seater', model: 'Force', imageUrl: car5 },
    { brand: '7 Seater', model: 'Venture Innova', imageUrl: car6 },
    { brand: '7 Seater', model: 'Kia Carens', imageUrl: car7 },
    { brand: '13 Seater', model: 'Mini Bus', imageUrl: car8 },
    { brand: '4 Seater', model: 'Swift', imageUrl: car9 },
    { brand: '4 Seater', model: 'Tata Zest', imageUrl: car10 },
    { brand: '8 Seater', model: 'Tata Magic', imageUrl: car11 },
    { brand: '9 Seater', model: 'Tavera', imageUrl: car12 },
    // Add more cars as needed
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <Slider {...settings}>
      {cars.map((car, index) => (
        <div key={index} style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img
            src={car.imageUrl}
            alt={`${car.brand} ${car.model}`}
            style={{
              filter: 'drop-shadow(1px 2px 3px black)',
              width: '100%',
              maxWidth: '400px',
              textAlign: 'center',
              display: 'inline',
              justifyItems: 'center'
              // Set your desired maximum width for desktop
            }}
          />
          <div style={{ textAlign: 'center', marginTop: 10 }}>
            <p style={{ fontWeight: '700' }}>{car.brand} - <b>{car.model}</b></p>
            {/* <p>{car.model}</p> */}
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default Cars;
