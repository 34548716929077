
import React from 'react';

const RaceFlagDivider = () => {
  return (
    <div className="race-flag-divider" style={{}}>
      <hr  className='lg' alt="race-flag" />
    </div>
  );
};

export default RaceFlagDivider;

