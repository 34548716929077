import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { FormControl, FormHelperText } from '@mui/material';

const EnquiryForm = ({ onSubmit }) => {
  const [formData, setFormData] = useState({
    fromAddress: '',
    toAddress: '',
    phoneNumber: '',
    date: '',
    carType: '',
    carModel: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Send data to Telegram
      const telegramBotToken = '6455240025:AAGCWVRODDajhOT-yuF_swOJpkj519yrNDw';
      const chatId = '5875242066';
      const telegramApiEndpoint = `https://api.telegram.org/bot${telegramBotToken}/sendMessage`;

      const message = `New Enquiry:
        From Address: ${formData.fromAddress}
        To Address: ${formData.toAddress}
        Phone Number: ${formData.phoneNumber}
        Date: ${formData.date}
        Car Type: ${formData.carType}
        Car Model: ${formData.carModel}`;

      await axios.post(telegramApiEndpoint, {
        chat_id: chatId,
        text: message,
        parse_mode: 'Markdown', // Optional: You can change the message formatting
      });

      onSubmit(formData); // You can still perform the local submission if needed

      alert('Submitted successfully! Eluru Cabs will contact you shortly.');
    } catch (error) {
      console.error('Failed to submit form:', error);
      alert('Oops! Something went wrong. Please try again later.');
    }

    // Reset form after submission
    setFormData({
      fromAddress: '',
      toAddress: '',
      phoneNumber: '',
      date: '',
      carType: '',
      carModel: '',
    });
  };

  return (
    <div className="enquiry-form-container">
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="From Address"
              type="text"
              name="fromAddress"
              value={formData.fromAddress}
              onChange={handleChange}
              required
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="To Address"
              type="text"
              name="toAddress"
              value={formData.toAddress}
              onChange={handleChange}
              required
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Phone Number"
              type="tel"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              required
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Date"
              type="date"
              name="date"
              value={formData.date}
              onChange={handleChange}
              required
              fullWidth
              margin="normal"
              InputProps={{ style: { color: 'black' } }}
              defaultValue={getCurrentDate()}  // Set default value to current date
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal">

              <InputLabel htmlFor="car-type-select">Car Type</InputLabel>

              <Select
                label="Car Type"
                name="carType"
                value={formData.carType}
                onChange={handleChange}
              >

                <MenuItem value="" disabled>
                  Select Car Type
                </MenuItem>

                <MenuItem value="2 seater">2 Seater</MenuItem>
                <MenuItem value="4 seater">4 Seater</MenuItem>
                <MenuItem value="7 seater">7 Seater</MenuItem>
                <MenuItem value="9 seater">9 Seater</MenuItem>
                <MenuItem value="13 seater">13 Seater</MenuItem>
                <MenuItem value="20 seater">20 Seater</MenuItem>
                <MenuItem value="40 seater">40 Seater</MenuItem>
                <MenuItem value="traveller">Traveller</MenuItem>
                <MenuItem value="bus">Bus</MenuItem>
                <MenuItem value="mini bus">Mini Bus</MenuItem>
                <MenuItem value="luxury">Luxury</MenuItem>
                <MenuItem value="Option not available">Option not available</MenuItem>
              </Select>
              <FormHelperText>Optional</FormHelperText>

            </FormControl>

          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel htmlFor="car-model-select">Car Model</InputLabel>
              <Select
                label="Car Model"
                name="carModel"
                value={formData.carModel}
                onChange={handleChange}
              >
                <MenuItem value="" disabled>
                  Select Car Model
                </MenuItem>
                <MenuItem value="Swift Dzire">Swift Dzire - 4 seater</MenuItem>
                <MenuItem value="Tata Zest">Tata Zest - 4 seater</MenuItem>
                <MenuItem value="Swift">Swift - 4 seater</MenuItem>
                <MenuItem value="Toyota Etios">Toyota Etios - 4 seater</MenuItem>
                <MenuItem value="Ertiga">Ertiga - 7 seater</MenuItem>
                <MenuItem value="Innova">Innova - 7 seater</MenuItem>
                <MenuItem value="Innova Crysta">Innova Crysta - 7 seater</MenuItem>
                <MenuItem value="Tavera Qualis">Tavera Qualis - 9 seater</MenuItem>
                <MenuItem value="Tata Magic">Tata Magic - 8 seater</MenuItem>
                <MenuItem value="Force">Force - 13 seater</MenuItem>
                <MenuItem value="Mini Bus">Mini Bus - 17 seater</MenuItem>
                <MenuItem value="Traveller">Traveller - 13 seater</MenuItem>
                <MenuItem value="Option not available">Option not available</MenuItem>
              </Select>
              <FormHelperText>Optional</FormHelperText>

            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" style={{ color: "white", background: "black" }} fullWidth>
              Book a Ride Now
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default EnquiryForm;
