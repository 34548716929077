import React, { useState } from 'react';
import './App.css';
import EnquiryForm from './Components/EnquiryForm';
import RaceFlagDivider from './Components/RaceFlagDivider';
import Grid from '@mui/material/Grid';
import { Navbar, Nav, Container, Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import cab from "./Images/cab.jpg"
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import LocalTaxiIcon from '@mui/icons-material/LocalTaxi';
import ScheduleIcon from '@mui/icons-material/Schedule';
import PeopleIcon from '@mui/icons-material/People';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { styled } from '@mui/system';
import { Helmet } from 'react-helmet';
import { ListItemText } from '@mui/material';
import Cars from './Components/Cars';
import Services from './Components/Services';
import cabIcon from './Images/cabIcon.png'
import CallIcon from '@mui/icons-material/Call';
import Testimonials from './Components/Testimonials';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import InstallPrompt from './Components/InstallPrompt';
import 'bootstrap/dist/css/bootstrap.min.css';

import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';

import Fab from '@mui/material/Fab';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TouchAppIcon from '@mui/icons-material/TouchApp';


const AnimatedGridItem = styled(Grid)({
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
        transform: 'scale(1.1)',
    },
});

const ItemContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 20,
});

function FullPage() {
    const handleEnquirySubmit = (formData) => {
        console.log('Form Data:', formData);
        alert('Submitted successfully! Eluru Cabs will contact you shortly.');
    };
    // const handlePhoneClick = () => {
    //   // Replace '1234567890' with your actual phone number
    //   window.location.href = 'tel:+91 7013116520';
    // };

    const scrollToSection = (sectionId) => {
        scroll.scrollTo(sectionId, {
            duration: 800,
            smooth: 'easeInOutQuart',
        });
    };

    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState('');

    const handlePhoneClick = () => {
        setModalOpen(true);
    };

    const handlePhoneNumberClick = (phoneNumber) => {
        setSelectedPhoneNumber(phoneNumber);
        // Perform any action with the selected phone number, e.g., initiate a call
        window.location.href = 'tel:' + phoneNumber;

        // Close Material-UI modal
        setModalOpen(false);
    };

    const handleCloseModal = () => {
        // Clear selected phone number and close Material-UI modal
        setSelectedPhoneNumber('');
        setModalOpen(false);
    };



    const handleWhatsAppClick = () => {
        const phoneNumber = '+919390305662'; // Replace with the actual WhatsApp number
        const message = 'Hello Eluru nani car travels';

        const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.location.href = whatsappLink;
    };

    return (
        <div className="app-container" >




            {/* <InstallPrompt/> */}
            <Helmet>
                <script type="application/ld+json">{/* Your existing JSON-LD script here */}</script>
                <title>Eluru nani car travels - Top Travles in Eluru , Your Premier Choice for Cabs in Eluru | Book Reliable Taxi Services, Call us on 093903 05662</title>
                <meta name="description" content="Explore the top cab service in Eluru with Your Brand Name. Reliable, comfortable, and on-time transportation. Call us at +91 9390305662 for bookings. " />
                {/* Add the following meta tag to enable the call option */}
                <meta name="format-detection" content="telephone=no" />

                {/* Replace '1234567890' with your actual mobile number */}
                <meta name="msapplication-task" content="name=Call;action-uri=tel:+919390305662" />
                <meta name="msapplication-task-separator" content=";" />


                <meta name="keywords" content="cab service, taxi booking, travel agency, car rental, airport transportation, local transportation, ride-sharing, city tours, chauffeur service, taxi app, mechanic help, roadside assistance, vehicle maintenance, driver service, travel assistance, tour operator, online cab booking, shuttle service, door-to-door transport, private hire, top cabs in Eluru, cabs in Eluru, travels in Eluru, Eluru cabs, Eluru travels, opting services in Eluru, mechanics in Eluru" />
                <meta name="author" content="Eluru nani car travels" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />

                <script type="application/ld+json">{`
          {
            "@context": "http://schema.org",
            "@type": "LocalBusiness",
            "name": "Your Cab Service Name",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Eluru",
              "addressRegion": "AP",
              "postalCode": "534001",
              "streetAddress": "Your Street Address"
            },
            "telephone": "+91 9390305662"
          }
        `}</script>
            </Helmet>

            {/* Responsive Navbar using React Bootstrap */}
            <Navbar expand="lg" bg="dark" variant="dark" className="justify-content-between">
                <Container>
                    <Navbar.Brand href="#">
                        <Image src={cabIcon} height={30} style={{ marginRight: '5px' }} />
                        <b style={{ color: 'white' }}>Eluru nani car travels</b>
                    </Navbar.Brand>
                    <button
                        onClick={handlePhoneClick}
                        id='onlyMobile'
                        className="nav-link btn btn-warning d-flex d-lg-none" // Display only on mobile
                        style={{ textDecoration: 'none', border: 'none', background: 'transparent', cursor: 'pointer' }}
                    >
                        <CallIcon />
                    </button>
                    <Navbar.Toggle aria-controls="navbarNavDropdown" />
                    <Navbar.Collapse id="navbarNavDropdown">
                        <Nav className='ml-auto' >
                            {/* <Nav.Item>
              <Nav.Link href="#" as={ScrollLink} to="home" onClick={() => scrollToSection("home")} className="nav-link btn btn-warning" style={{ textDecoration: 'none' }}>
                Home
              </Nav.Link>
            </Nav.Item> */}
                            <Nav.Item>
                                <Nav.Link as={ScrollLink} to="models" smooth={true} duration={800} className="nav-link btn btn-warning" style={{ textDecoration: 'none' }}>
                                    Models
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link as={ScrollLink} to="services" smooth={true} duration={800} className="nav-link btn btn-warning" style={{ textDecoration: 'none' }}>
                                    Services
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link as={ScrollLink} to="testimonials" smooth={false} duration={800} className="nav-link btn btn-warning" style={{ textDecoration: 'none' }}>
                                    Reviews
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link as={ScrollLink} to="directions" smooth={false} duration={800} className="nav-link btn btn-warning" style={{ textDecoration: 'none' }}>
                                    Directions
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link as={ScrollLink} to="spl" smooth={false} duration={800} className="nav-link btn btn-warning" style={{ textDecoration: 'none' }}>
                                    Special Services
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link as={ScrollLink} to="contact" smooth={false} duration={800} className="nav-link btn btn-warning" style={{ textDecoration: 'none' }}>
                                    Contact Us
                                </Nav.Link>
                            </Nav.Item>
                            {/* <Nav.Item>
              <Nav.Link href="#" as={ScrollLink} to="book-a-cab" onClick={() => scrollToSection("book-a-cab")} className="nav-link btn btn-warning" style={{ textDecoration: 'none' }}>
                Book a Cab
              </Nav.Link>
            </Nav.Item> */}
                        </Nav>
                        {/* Phone icon */}
                        {/* <button
            onClick={handlePhoneClick}
            className="nav-link btn btn-warning d-flex d-lg-none" // Display only on mobile
            style={{ textDecoration: 'none', border: 'none', background: 'transparent', cursor: 'pointer' }}
          >
            <CallIcon />
          </button> */}
                    </Navbar.Collapse>
                    {/* Phone icon for desktop */}
                    {/* <button
          onClick={handlePhoneClick}
          className="nav-link btn btn-warning d-none d-lg-flex ml-auto" // Display only on desktop
          style={{ textDecoration: 'none', border: 'none', background: 'transparent', cursor: 'pointer' }}
        >
          <CallIcon />  <b style={{marginLeft:'15px'}}>Call Us</b>
        </button> */}

                    {/* Phone icon for desktop */}
                    <button
                        onClick={handlePhoneClick}
                        className="nav-link btn btn-warning d-none d-lg-flex ml-auto"
                        style={{ textDecoration: 'none', border: 'none', background: 'transparent', cursor: 'pointer' }}
                    >
                        <CallIcon style={{ background: 'white' }} /> <b style={{ marginLeft: '15px', color: 'white' }}>Call Us</b>
                    </button>

                    {/* Material-UI Modal for phone numbers */}
                    <Modal
                        open={isModalOpen}
                        onClose={handleCloseModal}
                        aria-labelledby="phone-modal-title"
                        aria-describedby="phone-modal-description"
                    >
                        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px' }}>
                            <Typography variant="h6" id="phone-modal-title">
                                Choose a phone number:
                            </Typography>
                            <Button onClick={() => handlePhoneNumberClick('+91 9390305662')}>+91 9390305662</Button><br></br>
                            <Button onClick={() => handlePhoneNumberClick('+91 9966256889')}>+91 9966256889</Button><br></br>
                            {/* Add more buttons as needed */}
                            {/* <Button onClick={handleCloseModal}>Cancel</Button> */}
                        </div>
                    </Modal>


                </Container>
            </Navbar>
            <Fab
                color="success"
                style={{ position: 'fixed', bottom: '20px', right: '20px' }}
                onClick={handleWhatsAppClick}
            >
                <WhatsAppIcon />
            </Fab>



            {/* <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
        <Container>
          <Navbar.Brand href="#">Eluru Cabs</Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link href="#">Home</Nav.Link>
              <Nav.Link href="#">About</Nav.Link>
              <Nav.Link href="#">Models</Nav.Link>
              <Nav.Link href="#">Testimonials</Nav.Link>
              <Nav.Link href="#">Contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar> */}

            {/* Home Section */}

            {/* <RaceFlagDivider /> */}

            {/* Enquiry Form Section */}

            {/* <Grid >
                <div style={{ marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <span style={{ fontWeight: 700, margin: '0 10px' }}>          Click here to<TouchAppIcon />
                    </span>
                    <a href="https://instagram.com/siddarthcartravels?utm_source=qr&igshid=OGU0MmVlOWVjOQ==" target="_blank" rel="noopener noreferrer" style={{ marginRight: '10px', textDecoration: 'none', color: 'inherit' }}>
                        <img width="48" height="48" src="https://img.icons8.com/fluency/48/instagram-new.png" alt="instagram-new" />          </a>
                    <a href="https://www.facebook.com/profile.php?id=61553934107576&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer" style={{ marginRight: '10px', textDecoration: 'none', color: 'inherit' }}>
                        <img width="48" height="48" src="https://img.icons8.com/color/48/facebook.png" alt="facebook" />          </a>
                    <a href="https://www.youtube.com/@nanisiddunurinani4885" target="_blank" rel="noopener noreferrer" style={{ marginRight: '10px', textDecoration: 'none', color: 'inherit' }}>
                        <img width="48" height="48" src="https://img.icons8.com/color/48/youtube-play.png" alt="youtube-play" />          </a>

                </div>
            </Grid> */}

            <Grid container justifyContent="right" spacing={2} style={{ minHeight: '90vh' }}>

                <Grid item xs={12} md={8} lg={6}>
                    <Image src={cab} width={'100%'} />
                </Grid>

                <Grid item xs={12} md={8} lg={6}>
                    <div className="section section-white enquiry-form-container">
                        <EnquiryForm onSubmit={handleEnquirySubmit} />
                    </div>

                </Grid>

            </Grid>
            <RaceFlagDivider />





            {/* About Section */}
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                    {/* Left Grid */}
                    <Grid item xs={12} md={6} >
                        <Paper elevation={3} sx={{ padding: '50px 30px', height: '100%', justifyContent: 'center', justifyItems: 'center', display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="h4" gutterBottom sx={{ fontWeight: '700' }}>
                                About Eluru nani car travels
                            </Typography>

                            <Typography paragraph>
                                Eluru nani car travels is your premier choice for top cabs in Eluru. We provide reliable and comfortable transportation services, making us a leading option for both locals and visitors in the region.
                            </Typography>

                            <Typography variant="h4" gutterBottom sx={{ fontWeight: '700' }}>
                                Our Mission
                            </Typography>

                            <Typography paragraph>
                                At Eluru nani car travels, our mission is to redefine your travel experience. We aim to offer more than just transportation; we strive to make every journey with us memorable, convenient, and safe. Your satisfaction is our priority, and we go the extra mile to ensure you reach your destination with ease and comfort.
                            </Typography>
                        </Paper>
                    </Grid>





                    {/* Right Grid */}
                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} sx={{ padding: '20px', height: '100%', background: 'black', color: 'white' }}>
                            <Typography variant="h5" gutterBottom sx={{ fontWeight: 700 }}>
                                Why Choose Eluru nani car travels?
                            </Typography>

                            <List>
                                <ListItem>
                                    <ListItemText
                                        primary="Reliability"
                                        secondary="Count on us for punctual and dependable services."
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary="Comfort"
                                        secondary="Enjoy a comfortable ride in our well-maintained fleet of vehicles."
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary="Experienced Drivers"
                                        secondary="Our professional drivers are trained and experienced to prioritize your safety and satisfaction."
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary="Wide Range of Services"
                                        secondary="Whether you need a quick ride across town or an airport transfer, Eluru nani car travels has you covered."
                                    />
                                </ListItem>
                            </List>

                            <Typography variant="h5" gutterBottom sx={{ fontWeight: 700 }}>
                                Contact us
                            </Typography>
                            <Typography paragraph>
                                Ready to experience the Eluru nani car travels difference? Book your ride today and discover the convenience of traveling with Eluru cabs. For inquiries or assistance, feel free to reach out to our friendly customer support team at{' '}
                                <strong onClick={handlePhoneClick} style={{ cursor: 'pointer' }}><br></br>Call us</strong>.
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>




            <RaceFlagDivider />

            <div id="services" className="section " style={{ color: 'white' }}>

                <div style={{ padding: 20, textAlign: 'center' }}>
                    <Typography variant="h4" style={{
                        color: 'black', padding: 10, borderRadius: 5, marginBottom: 20, textShadow: '2px 2px grey', fontWeight: '900', fontFamily: 'Merriweather, sans-serif', // Use Merriweather font
                    }}>
                        OUR SERVICES
                    </Typography>
                    <Grid container spacing={3} justifyContent="space-around">
                        <AnimatedGridItem item xs={12} sm={6} md={4} lg={2}>
                            <Paper elevation={3}>
                                <ItemContainer>
                                    <LocalTaxiIcon fontSize="large" style={{ minHeight: '100px' }} />
                                    <Typography variant="caption" style={{ marginTop: 10 }}>24/7 availability</Typography>
                                    <strong onClick={handlePhoneClick} style={{ cursor: 'pointer' }}>Call us</strong>.
                                </ItemContainer>
                            </Paper>
                        </AnimatedGridItem>
                        <AnimatedGridItem item xs={12} sm={6} md={4} lg={2}>
                            <Paper elevation={3}>
                                <ItemContainer >
                                    <ScheduleIcon fontSize="large" style={{ minHeight: '100px' }} />
                                    <Typography variant="caption" style={{ marginTop: 10 }}>On-time service</Typography>
                                    <strong onClick={handlePhoneClick} style={{ cursor: 'pointer' }}>Call us</strong>.
                                </ItemContainer>
                            </Paper>
                        </AnimatedGridItem>
                        <AnimatedGridItem item xs={12} sm={6} md={4} lg={2}>
                            <Paper elevation={3}>
                                <ItemContainer>
                                    <PeopleIcon fontSize="large" style={{ minHeight: '100px' }} />
                                    <Typography variant="caption" style={{ marginTop: 10 }}>Professional drivers</Typography>
                                    <strong onClick={handlePhoneClick} style={{ cursor: 'pointer' }}>Call us</strong>.
                                </ItemContainer>
                            </Paper>
                        </AnimatedGridItem>
                        <AnimatedGridItem item xs={12} sm={6} md={4} lg={2}>
                            <Paper elevation={3}>
                                <ItemContainer>
                                    <EmojiTransportationIcon fontSize="large" style={{ minHeight: '100px' }} />
                                    <Typography variant="caption" style={{ marginTop: 10 }}>Wide range of vehicles</Typography>
                                    <strong onClick={handlePhoneClick} style={{ cursor: 'pointer' }}>Call us</strong>.
                                </ItemContainer>
                            </Paper>
                        </AnimatedGridItem>
                        <AnimatedGridItem item xs={12} sm={6} md={4} lg={2}>
                            <Paper elevation={3}>
                                <ItemContainer>
                                    <CurrencyRupeeIcon fontSize="large" style={{ minHeight: '100px' }} />
                                    <Typography variant="caption" style={{ marginTop: 10 }}>Transparent pricing</Typography>
                                    <strong onClick={handlePhoneClick} style={{ cursor: 'pointer' }}>Call us</strong>.
                                </ItemContainer>
                            </Paper>
                        </AnimatedGridItem>
                    </Grid>
                </div>


                {/* <h1>Eluru Cabs</h1>
  <p>Your trusted cab services</p> */}
            </div>


            {/* Models Section */}
            <div id="models" className="section section-white">
                <h2>Our Vehicles</h2>
                <p>Explore our fleet of cars</p>
                <Cars />
            </div>
            <RaceFlagDivider />



            {/* Testimonials Section */}
            <div id="testimonials" className="section" style={{ background: 'black', color: 'white' }}>
                <h2>Testimonials</h2>
                <p>See what our customers say about us</p>
                <Testimonials />
            </div>
            <RaceFlagDivider />
            <div id='spl'>
                <Services />
            </div>
            {/* Contact Section */}
            <div id='directions' className="section section-white">
                <h2>Directions to Eluru nani car travels</h2><br></br>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3820.961043401293!2d81.06828687515016!3d16.728795484050398!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTbCsDQzJzQzLjciTiA4McKwMDQnMTUuMSJF!5e0!3m2!1sen!2sin!4v1714214966485!5m2!1sen!2sin"
                    width="100%" // Set width to 100% to make it responsive
                    height="450"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    title='Eluru nani car travels'
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                />
            </div>

            {/* Footer */}
            {/* <div id='contact' className="" style={{ background: '#ffd700', padding: '30px' }}>
        <h3>Siddarth Car Travels</h3>
        <p>1-74/1, Sanivarapu peta, Bommala Center, Sitha Gardens,Eluru, 534003</p>
        <p onClick={handlePhoneClick} style={{ fontWeight: '600', cursor: 'pointer' }}>Call Us</p>
      </div> */}

            <div id='contact' style={{ background: 'black', color: 'white', padding: '30px' }}>
                <h3>                        <Image src={cabIcon} height={30} style={{ marginRight: '0.5rem' }} />
                    Eluru nani car travels</h3>

                <p onClick={() => handlePhoneNumberClick('093903 05662')}><h5 style={{ cursor: 'pointer' }}>093903 05662</h5></p>
                <p onClick={handlePhoneClick} style={{ fontWeight: '600', cursor: 'pointer' }}><CallIcon /> Call Us</p>

                {/* Social Media Icons */}
                <div style={{ marginTop: '20px' }}> <span style={{ fontWeight: 700, margin: '0 10px' }}>connect with us at</span>
                    <a href="https://instagram.com/siddarthcartravels?utm_source=qr&igshid=OGU0MmVlOWVjOQ==" target="_blank" rel="noopener noreferrer" style={{ marginRight: '10px', textDecoration: 'none', color: 'inherit' }}>
                        <InstagramIcon fontSize="large" />
                    </a>
                    <a href="https://www.facebook.com/profile.php?id=61553934107576&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer" style={{ marginRight: '10px', textDecoration: 'none', color: 'inherit' }}>
                        <FacebookIcon fontSize="large" />
                    </a>
                    <a href="https://www.youtube.com/@nanisiddunurinani4885" target="_blank" rel="noopener noreferrer" style={{ marginRight: '10px', textDecoration: 'none', color: 'inherit' }}>
                        <YouTubeIcon fontSize="large" />
                    </a>
                </div>
                <p style={{ padding: '1rem' }}>H.No: 3-86, Mala Palli, RCM church Back Side, Pedavegi Mandalam, Duggirala, Sanivarapu Peta, West Godavari, Andhra Pradesh, 534003</p>

            </div>


        </div>
    );
}

export default FullPage;
